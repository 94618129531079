// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-components-carousel-art-js": () => import("./../src/pages/components/CarouselArt.js" /* webpackChunkName: "component---src-pages-components-carousel-art-js" */),
  "component---src-pages-components-carousel-design-js": () => import("./../src/pages/components/CarouselDesign.js" /* webpackChunkName: "component---src-pages-components-carousel-design-js" */),
  "component---src-pages-components-carousel-digital-js": () => import("./../src/pages/components/CarouselDigital.js" /* webpackChunkName: "component---src-pages-components-carousel-digital-js" */),
  "component---src-pages-components-design-art-js": () => import("./../src/pages/components/DesignArt.js" /* webpackChunkName: "component---src-pages-components-design-art-js" */),
  "component---src-pages-components-design-boxes-js": () => import("./../src/pages/components/DesignBoxes.js" /* webpackChunkName: "component---src-pages-components-design-boxes-js" */),
  "component---src-pages-components-design-digital-js": () => import("./../src/pages/components/DesignDigital.js" /* webpackChunkName: "component---src-pages-components-design-digital-js" */),
  "component---src-pages-components-design-graphic-js": () => import("./../src/pages/components/DesignGraphic.js" /* webpackChunkName: "component---src-pages-components-design-graphic-js" */),
  "component---src-pages-components-design-js": () => import("./../src/pages/components/Design.js" /* webpackChunkName: "component---src-pages-components-design-js" */),
  "component---src-pages-components-education-js": () => import("./../src/pages/components/Education.js" /* webpackChunkName: "component---src-pages-components-education-js" */),
  "component---src-pages-components-experience-js": () => import("./../src/pages/components/Experience.js" /* webpackChunkName: "component---src-pages-components-experience-js" */),
  "component---src-pages-components-info-js": () => import("./../src/pages/components/Info.js" /* webpackChunkName: "component---src-pages-components-info-js" */),
  "component---src-pages-components-intro-js": () => import("./../src/pages/components/Intro.js" /* webpackChunkName: "component---src-pages-components-intro-js" */),
  "component---src-pages-components-music-js": () => import("./../src/pages/components/Music.js" /* webpackChunkName: "component---src-pages-components-music-js" */),
  "component---src-pages-components-popup-coffee-js": () => import("./../src/pages/components/PopupCoffee.js" /* webpackChunkName: "component---src-pages-components-popup-coffee-js" */),
  "component---src-pages-components-popup-crum-js": () => import("./../src/pages/components/PopupCrum.js" /* webpackChunkName: "component---src-pages-components-popup-crum-js" */),
  "component---src-pages-components-popup-dads-js": () => import("./../src/pages/components/PopupDads.js" /* webpackChunkName: "component---src-pages-components-popup-dads-js" */),
  "component---src-pages-components-popup-friend-js": () => import("./../src/pages/components/PopupFriend.js" /* webpackChunkName: "component---src-pages-components-popup-friend-js" */),
  "component---src-pages-components-popup-game-js": () => import("./../src/pages/components/PopupGame.js" /* webpackChunkName: "component---src-pages-components-popup-game-js" */),
  "component---src-pages-components-popup-link-js": () => import("./../src/pages/components/PopupLink.js" /* webpackChunkName: "component---src-pages-components-popup-link-js" */),
  "component---src-pages-components-popup-mex-js": () => import("./../src/pages/components/PopupMex.js" /* webpackChunkName: "component---src-pages-components-popup-mex-js" */),
  "component---src-pages-components-popup-monke-js": () => import("./../src/pages/components/PopupMonke.js" /* webpackChunkName: "component---src-pages-components-popup-monke-js" */),
  "component---src-pages-components-popup-nobody-js": () => import("./../src/pages/components/PopupNobody.js" /* webpackChunkName: "component---src-pages-components-popup-nobody-js" */),
  "component---src-pages-components-popup-scan-js": () => import("./../src/pages/components/PopupScan.js" /* webpackChunkName: "component---src-pages-components-popup-scan-js" */),
  "component---src-pages-components-projects-boxes-js": () => import("./../src/pages/components/ProjectsBoxes.js" /* webpackChunkName: "component---src-pages-components-projects-boxes-js" */),
  "component---src-pages-components-projects-js": () => import("./../src/pages/components/Projects.js" /* webpackChunkName: "component---src-pages-components-projects-js" */),
  "component---src-pages-components-projects-new-js": () => import("./../src/pages/components/ProjectsNew.js" /* webpackChunkName: "component---src-pages-components-projects-new-js" */),
  "component---src-pages-components-scroll-js": () => import("./../src/pages/components/Scroll.js" /* webpackChunkName: "component---src-pages-components-scroll-js" */),
  "component---src-pages-components-sidebar-js": () => import("./../src/pages/components/Sidebar.js" /* webpackChunkName: "component---src-pages-components-sidebar-js" */),
  "component---src-pages-components-topbar-js": () => import("./../src/pages/components/Topbar.js" /* webpackChunkName: "component---src-pages-components-topbar-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

